// This utility helps detect new deployments and clear storage when needed

// Get the build ID that Next.js generates for each build
export async function getBuildId(): Promise<string> {
  try {
    const response = await fetch("/api/build-id");
    if (!response.ok) {
      throw new Error("Failed to fetch build ID");
    }
    return await response.text();
  } catch (error) {
    console.error("Error fetching build ID:", error);
    // Fallback to timestamp if we can't get the build ID
    return Date.now().toString();
  }
}

// Check if the current build ID is different from the stored one
export async function checkAndClearStorage(storageKey: string): Promise<void> {
  if (typeof window === "undefined") return;

  const buildId = await getBuildId();
  const storedBuildId = localStorage.getItem("app-build-id");

  if (storedBuildId !== buildId) {
    // New deployment detected, clear the specified storage
    localStorage.removeItem(storageKey);
    // Update the stored build ID
    localStorage.setItem("app-build-id", buildId);
    console.log("New deployment detected, cleared storage:", storageKey);
  }
}
