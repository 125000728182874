"use client";

import { useEffect } from "react";

import { checkAndClearStorage } from "@/app/utils/versionCheck";

interface VersionCheckerProps {
  storageKeys: string[];
}

export function VersionChecker({ storageKeys }: VersionCheckerProps) {
  useEffect(() => {
    // Check for new deployments and clear storage if needed
    storageKeys.forEach((key) => {
      checkAndClearStorage(key);
    });
  }, [storageKeys]);

  // This component doesn't render anything
  return null;
}
